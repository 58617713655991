import React from "react"
import { graphql, Link } from "gatsby"
import IdeetippList from "../components/IdeetippList"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const TagTemplate = ({ data, pageContext }) => {
  const ideetipp = data.allContentfulIdeetipp.nodes
  return (
    <Layout>
      <SEO title={pageContext.tag} />
      <main className="page">
        <h2>{pageContext.tag}</h2>

        <div className="tag-recipes">
          <IdeetippList ideetipp={ideetipp} />
          <Link key="back" to={`/ideen+tipps/`} className="link">
            {"< Zurück"}
          </Link>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query GetIdeetippByTag($tag: String) {
    allContentfulIdeetipp(
      sort: { order: ASC, fields: title }
      filter: { content: { tags: { eq: $tag } } }
    ) {
      nodes {
        title
        id
        subtitle
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  }
`

export default TagTemplate
